<template>
  <sign-page
    title-text="验收管理"
    :request="request"
    :column-list="columnList"
    table-size="small"
    ref="mPage"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'new', label: '新增'}]"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :tableActionsWidth="260"
    :table-actions="tableActions"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal :title="chooseData ? '修改验收单' : '新增验收单'" :mask-closable="false" v-model="modelForm" theme="mh-blackt" width="1000px">
      <data-form :purchase-list="purchaseList" v-if="modelForm" ref="dataForm" :data="chooseData"></data-form>
      <div slot="footer" class="form-btn">
        <fm-btn style="margin-right: 50px;" @click="save">保存</fm-btn>
        <fm-btn @click="modelForm = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-modal title="验收单明细" v-model="modelDetail" theme="mh-blackt" width="800px">
      <data-detail v-if="chooseData" :data="chooseData"></data-detail>
      <div slot="footer" class="form-btn">
        <fm-btn @click="modelDetail = false">关闭</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  acceptanceRequest as request,
  purchaseRequest
} from '../../api'

import dataForm from './components/form'
import dataDetail from './components/detail'
import Content from './components/content'

export default {
  components: {
    dataForm,
    dataDetail
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'detail',
        label: '明细'
      },
      {
        key: 'editData',
        label: '修改'
      },
      {
        key: 'start',
        label: '开始验收',
        show: (d) => {
          return d.status === 'draw'
        }
      },
      {
        key: 'end',
        label: '结束',
        show: (d) => {
          return d.status === 'doing'
        }
      },
      {
        key: 'reStart',
        label: '重新验收',
        show: (d) => {
          return d.status === 'end'
        }
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    columnList () {
      return [{
        title: '验收单编号',
        field: 'code',
        sort: true
      },
      {
        title: '验收内容',
        field: 'content',
        sort: true,
        render: (h, row) => {
          return h(Content, {
            props: {
              content: row.content
            }
          })
        }
      },
      {
        title: '验收日期',
        field: 'acceptanceTime',
        sort: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.acceptanceTime ? rowData.acceptanceTime.slice(0, 10) : '-')
        }
      },
      {
        title: '验收情况',
        field: 'statusLabel',
        sort: true
      },
      {
        title: '使用科室',
        field: 'useOrgName',
        sort: true
      },
      {
        title: '职能科室',
        field: 'purchaseOrgName',
        sort: true
      },
      {
        title: '固定资产管理科室',
        field: 'acceptanceOrgName',
        sort: true
      },
      {
        title: '创建部门',
        field: 'orgName',
        sort: true
      }]
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      purchaseList: [],
      modelDetail: false,
      modelForm: false,
      chooseData: null,
      statusList: [{key: 'draw', label: '创建中'}, {key: 'doing', label: '验收中'}, {key: 'end', label: '已验收'}],
    }
  },
  created () {
    if (this.$authFunsProxy.new || this.$authFunsProxy.editData) {
      this.$store.dispatch('loadOrgList')
      this.loadPurchase()
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm && parm.length > 0 ? parm.join(',') : null
      })
      pageVm.loadData()
    },
    async loadPurchase () {
      let data = await purchaseRequest.get()
      this.purchaseList = data.map(v => {
        let label = v.code || ''
        label += (v.goodsName || '') + ' ' + (v.userName || '') + ' ' + (v.total || '') + '元'
        return {
          key: v.id,
          label,
          data: v
        }
      })
    },
    tableAction (p) {
      this.chooseData = p.data
      let action = p.action
      if (action === 'editData') {
        this.modelForm = true
      } else if (action === 'start') {
        this.changeStatus(this.chooseData.id, 'doing', '开始验收')
      } else if (action === 'end') {
        this.changeStatus(this.chooseData.id, 'end', '结束验收')
      } else if (action === 'reStart') {
        this.changeStatus(this.chooseData.id, 'doing', '重新验收')
      } else if (action === 'detail') {
        this.modelDetail = true
      }
    },
    async loadData () {
      let data = []
      if (this.$authFunsProxy.get) {
        data = await request.get()
      } else if (this.$authFunsProxy.getMyOrg) {
        data = await request.getMyOrgCreate()
      }
      data.forEach(v => v.statusLabel = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
      return data
    },
    clickTitleMenu (key) {
      if (key === 'new') {
        if (!this.$authFunsProxy.get && !this.$store.getters.currentOrgId) {
          this.$notify({
            title: '系统提示',
            message: '无当前组织架构，无法新建',
            type: 'info'
          })
          return
        }
        this.chooseData = null
        this.modelForm = true
      }
    },
    async changeStatus (id, newStatus, label) {
      let del = await this.$dialog.confirm({title: '系统提示', content: '确定' + label + '吗?'})
      if (del) {
        await request.update(id, {
          status: newStatus
        })
        this.$refs.mPage.loadData()
      }
    },
    async save () {
      let data = this.$refs.dataForm.getData()
      if (!data.content) {
        this.$notify({
          title: '系统提示',
          message: '请输入验收内容',
          type: 'info'
        })
        return
      }
      this.modelForm = false
      data.detailList.forEach(v => {
        if (String(v.id).includes('id_')) {
          delete v.id
        }
      })
      if (data.id) {
        await request.update(data.id, data)
      } else {
        data.status = 'draw'
        await request.add(data)
      }
      this.$refs.mPage.loadData()
    }
  }
}
</script>

<style lang="less" scoped>
.form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>